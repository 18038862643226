import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Header from "../components/header"
import Content from "../components/content"
import Footer from "../components/footer"
import SEO from "../components/seo"
import NewsPreview from '../components/news-preview'
import Img from "gatsby-image"

const HomePage = ({data}) => {
    const meta = data.graphAPI.homepage.meta
    const content = data.graphAPI.homepage.content
    return (
        <Layout>
            <Helmet>
                <meta name="msvalidate.01" content="3BD582E42A2A395372B77327B082044A" />
            </Helmet>
            <SEO title={meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    {content[0].__typename !== 'GRAPHAPI_ComponentPageSplitContentImage' ? (
                        <div className="title-section">
                            <h1 className="title is-size-1">{meta.title}</h1>
                        </div>
                    ) : (<></>)}
                    <Content content={content} />

                    <div className="">
                    <div style={{ marginTop: '2rem'}} className="title-section">
                        <h2 className="title is-size-1">Latest News </h2>
                    </div>
                    </div>
                    <NewsPreview posts={data.graphAPI.blogPosts} />
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default HomePage

export const homePageQuery = graphql`
    query homePage {
        graphAPI {
            homepage {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                }
            }
            blogPosts(limit: 6, sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


